const colors = {
    iron: "#D8D6D6",
    ironsideGrey: "#666666",
    white: "#ffffff",
    greyBlue: "#6b8ba7",
    nebula: "#C9DCD2",
    shadowGreen: "#98BEBE",
    summerGreen: "#8CBF9C",
    darkPeach: "#E67964",
    mountainMist: "#989898",
    cottonSeed: "#BCBCBC",
    silverChalice: "#ACACAC",
    smokeyGrey: "#707070",
    shockingOrange: "#EA5946",
    seaShell: "#ECF0F4",
    nileBlue: "#1A304C",
    whiteSmoke: "#F4F5F5",
    darkGunmetal: "#202131",
    lightOrange: "#F9EBEC",

    auratiumGreen: "#74AD85",
    forrestGreen: "#5e986f",
    seaGreen: "#99C2AD",
    sienna: "#E27867",
    agateGray: "#7F7F7F",
    slateGray: "#A7A9AC",
    amaranthRed: "#B94F4F",
    cardGreen: "#EEF5F1",
    pearlGray: "#E6E7E8",
    ipanemaBlue: "#329998",
    deepPetrol: "#0d6877",
};

const fontWeights = {
    thin: 100,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
};

const theme = {
    colors,
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    space: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    sizes: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    fonts: {
        roboto: "Roboto",
        montserrat: "Montserrat",
    },
    fontWeights,
    //         [0, 1,  2,  3,  4,  5,  6,  7,  8,  9 ]
    fontSizes: [9, 10, 11, 12, 14, 16, 18, 20, 27, 44],
    borders: {
        green: `1px solid #CCE1D6`,
        thickGreen: `2px solid ${colors.seaGreen}`,
        gray: `1px solid ${colors.pearlGray}`,
    },
    breakpoints: {
        desktop: 1250,
        tablet: 800,
    },
};

export default theme;
