import { createSlice } from "@reduxjs/toolkit";
import { UserInput } from "../../types/userInput";

const initialState: UserInput = {
    email: "",
    isCanada: false,
    practiceType: "generalDentist",
    searchArea: "",
    userName: "",
    practiceName: "",
    practiceManagementSoftware: "",
};

export default createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
