import React from "react";
import { Grid } from "../../styledSystemUtilities";

export const BlueContainer = ({
    gridTemplateColumns,
    children,
}: {
    gridTemplateColumns: string;
    children: React.ReactNode;
}) => (
    <Grid backgroundColor={"darkGunmetal"} gridColumn={"1 / -1"} gridTemplateColumns={gridTemplateColumns}>
        {children}
    </Grid>
);
