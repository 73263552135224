import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QualificationReport } from "../../types/qualificationReport";

const initialState: QualificationReport | null = null;

export default createSlice<QualificationReport | null, any>({
    name: "qualificationReport",
    initialState: initialState,
    reducers: {
        set: (state: QualificationReport | null, action: PayloadAction<QualificationReport>) => action.payload,
    },
});
