import _ from "lodash/fp";
import { Middleware } from "redux";

const noop: Middleware = ({ dispatch }) => (next) => (action) => {
    if (!_.isEmpty(action) && !_.isNil(action) && _.get("type", action)) {
        next(action);
    }
};

export default noop;
