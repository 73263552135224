import { Box, FlexColAlignCenter, FlexRowAlignCenter, Grid, YSpacer } from "../../styledSystemUtilities";
import {
    Body16,
    Body18,
    Header16,
    Header18Bold,
    Header18SemiBold,
    Header24Bold,
    Header36Bold,
    Header48Bold,
} from "../../typography";
import { OrangeButton } from "../../buttons/OrangeButton";
import React, { useState } from "react";
import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import { DropdownArrow } from "../../../assets";
import { Controller } from "react-hook-form";
import { object, string } from "yup";
import { AssessmentFormFields } from "../../../types/assessmentFormFields";
import { useReduxForm } from "../../../hooks/useReduxForm";
import { Input } from "../../Atoms/Input";
import { UserInput } from "../../../types/userInput";
import { useAppDispatch } from "../../../redux/hooks";
import { actions, slices } from "../../../redux";
import { useNavigate } from "react-router-dom";
import stepOne from "../../../assets/stepOne.svg";
import { FormError } from "../../Atoms/FormError";
import { AssessmentFormCopy } from "../../../App";
import { BigCheckbox } from "../../Atoms/Checkbox";

export const Select = <
    SelectOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(
    props: Props<SelectOption, IsMulti, Group>
): JSX.Element => {
    const customStyles: StylesConfig<SelectOption, IsMulti, Group> = {
        control: (base, state) => ({
            ...base,
            border: "none",
            height: "50px",
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "normal",
            color: "#BCBCBC",
            paddingLeft: "10px",
            paddingRight: "20px",
            borderRadius: 0,
            boxShadow: state.isFocused ? "0" : "0",
            ":hover": {
                cursor: "pointer",
                border: "none",
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: "#666666",
        }),
        placeholder: (base) => ({
            ...base,
            color: "#BCBCBC",
        }),
        menu: (base) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
            bottom: "450px",
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "500px",
            padding: 0,
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.2)",
        }),
        option: (base) => ({
            ...base,
            paddingLeft: "20px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "normal",
            color: "#666666",
            backgroundColor: "white",
            height: "50px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <DropdownArrow />,
            }}
            isSearchable={false}
            {...props}
        />
    );
};

const header = "Step 1";
const subHeader = "See if you qualify and how you compare to\n" + "other dentists in your zip code within seconds.";
const paragraph1 =
    "When you see if you qualify for our revolutionary service, a lot more is going on than meets the eye. Once you submit your information below, we identify a unique code that is linked to your Google My Business account. We then use that code to access dozens of key data points that help us understand exactly the competitive landscape in your area and what Ascent can do to help you flourish. ";
const paragraph2 =
    "Once qualified, you’ll start the process by setting up your account and scheduling a kick-off call with your Ascent Success Manager.";
const paragraph3 = "It’s a free assessment! Filling out this information does not obligate you to anything. ";

const pmsOptions: { value: string; label: string }[] = [
    {
        value: "dentrix",
        label: "Dentrix",
    },
    {
        value: "eaglesoft",
        label: "Eaglesoft",
    },
    {
        value: "openDental",
        label: "Open Dental",
    },
    {
        value: "dentrixAscend",
        label: "Dentrix Ascend",
    },
    {
        value: "other",
        label: "Other",
    },
];

const practiceTypeOptions = [
    { value: "generalDentist", label: "General Dentist" },
    { value: "cosmeticDentist", label: "Cosmetic Dentist" },
    { value: "pediatricDentist", label: "Pediatric Dentist" },
    { value: "prosthodontist", label: "Prosthodontist" },
    { value: "periodontist", label: "Periodontist" },
    { value: "endodontist", label: "Endodontist" },
    { value: "orthodontist", label: "Orthodontist" },
    { value: "oralSurgeon", label: "Oral Surgeon" },
];

const formBackgroundColor = "nebula";
const headerColor = "nileBlue";

export const AssessmentFormDesktop = ({ copyProps }: { copyProps: AssessmentFormCopy }) => {
    return (
        <Box backgroundColor={formBackgroundColor} borderRadius={"15px"} py={"68px"} px={"112px"}>
            <Header48Bold color={headerColor} textAlign={"center"}>
                {copyProps.header}
            </Header48Bold>
            <YSpacer spaceY={"26px"} />
            <FlexColAlignCenter>
                <Box width={"802px"}>
                    <Header24Bold color={headerColor} textAlign={"center"}>
                        {copyProps.subHeader}
                    </Header24Bold>
                </Box>
            </FlexColAlignCenter>

            <YSpacer spaceY={"53px"} />
            <Grid gridTemplateColumns={"485px 1fr"} alignItems={"center"} gridColumnGap={"25px"}>
                <Box>
                    <Body18 lineHeight={"27px"} whiteSpace={"pre-line"}>
                        {copyProps.paragraph1}
                        <br />
                        <br />
                        {copyProps.paragraph2}
                    </Body18>
                </Box>
                <img alt={"Image"} src={stepOne} />
            </Grid>
            <YSpacer spaceY={"35px"} />
            {copyProps.paragraph4 ? (
                <>
                    <Body18 lineHeight={"27px"} whiteSpace={"pre-line"}>
                        {copyProps.paragraph4}
                    </Body18>
                    <YSpacer spaceY={"40px"} />
                </>
            ) : null}

            <Body16 textAlign={"center"}>{copyProps.paragraph3}</Body16>
            <YSpacer spaceY={"36px"} />
            <Form gridTemplateColumns={"1fr 1fr"} />
        </Box>
    );
};

export const AssessmentFormTablet = ({ copyProps }: { copyProps: AssessmentFormCopy }) => {
    return (
        <Box backgroundColor={formBackgroundColor} borderRadius={"15px"} py={"50px"} px={"70px"}>
            <Header36Bold color={headerColor} textAlign={"center"}>
                {copyProps.header}
            </Header36Bold>
            <YSpacer spaceY={"26px"} />
            <FlexColAlignCenter>
                <Box width={"600px"}>
                    <Header18Bold color={headerColor} textAlign={"center"}>
                        {copyProps.subHeader}
                    </Header18Bold>
                </Box>
            </FlexColAlignCenter>

            <YSpacer spaceY={"53px"} />
            <Grid gridTemplateColumns={"1fr 1fr"} alignItems={"center"} gridColumnGap={"15px"}>
                <Box>
                    <Body16 lineHeight={"24px"} whiteSpace={"pre-line"}>
                        {copyProps.paragraph1}
                        <br />
                        <br />
                        {copyProps.paragraph2}
                    </Body16>
                </Box>
                <Box justifySelf={"center"}>
                    <img width={"300px"} alt={"Image"} src={stepOne} />
                </Box>
            </Grid>
            <YSpacer spaceY={"28px"} />
            {copyProps.paragraph4 ? (
                <>
                    <Body16 lineHeight={"24px"} whiteSpace={"pre-line"}>
                        {copyProps.paragraph4}
                    </Body16>
                    <YSpacer spaceY={"30px"} />
                </>
            ) : null}

            <Body16 textAlign={"center"}>{copyProps.paragraph3}</Body16>
            <YSpacer spaceY={"36px"} />
            <Form gridTemplateColumns={"1fr 1fr"} />
        </Box>
    );
};

export const AssessmentFormMobile = ({ copyProps }: { copyProps: AssessmentFormCopy }) => {
    return (
        <FlexColAlignCenter
            backgroundColor={formBackgroundColor}
            borderRadius={"15px"}
            pb={"40px"}
            pt={"22px"}
            px={"18px"}
        >
            <Header18SemiBold color={headerColor} textAlign={"center"}>
                {copyProps.header}
            </Header18SemiBold>
            <YSpacer spaceY={"10px"} />
            <Header16 color={headerColor} lineHeight={"20px"} textAlign={"center"}>
                {copyProps.subHeader}
            </Header16>

            <YSpacer spaceY={"20px"} />
            <img width={"280px"} alt={"Image"} src={stepOne} />
            <YSpacer spaceY={"20px"} />
            <Box maxWidth={"320px"}>
                <Body16 lineHeight={"24px"} whiteSpace={"pre-line"}>
                    {copyProps.paragraph1}
                    <br />
                    <br />
                    {copyProps.paragraph2}
                    {copyProps.paragraph4 ? (
                        <>
                            <br />
                            <br />
                            {copyProps.paragraph4}
                        </>
                    ) : null}

                    <br />
                    <br />
                    {copyProps.paragraph3}
                </Body16>
            </Box>

            <YSpacer spaceY={"18px"} />
            <Form gridTemplateColumns={"1fr"} buttonSpace={"30px"} />
        </FlexColAlignCenter>
    );
};

const schema = object().shape({
    name: string().required("Please enter your name.").trim(),
    email: string().required("Please enter your email.").trim(),
    practiceName: string().required("Please enter your practice name.").trim(),
    practiceZipCode: string().required("Please enter your zip code.").trim(),
    practiceManagementSoftwareType: object()
        .shape({
            value: string(),
            label: string(),
        })
        .nullable()
        .required("Please select a software type."),
    practiceType: object()
        .shape({
            value: string(),
            label: string(),
        })
        .nullable()
        .required("Please select a practice type."),
});

const Form = ({ gridTemplateColumns, buttonSpace = "52px" }: { gridTemplateColumns: string; buttonSpace?: string }) => {
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useReduxForm("assessmentForm", schema);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isCanada, setIsCanada] = useState(false);

    const onSubmit = (data: AssessmentFormFields) => {
        const input: UserInput = {
            userName: data.name,
            email: data.email,
            searchArea: data.practiceZipCode,
            practiceType: data.practiceType?.value ?? "generalDentist",
            practiceName: data.practiceName,
            isCanada: isCanada,
            practiceManagementSoftware: data.practiceManagementSoftwareType?.value ?? "other",
        };

        dispatch(actions.getPlaces(input));
        dispatch(slices.user.actions.set(input));
        navigate("/place-select");
    };

    return (
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Grid gridTemplateColumns={gridTemplateColumns} gridGap={"12px"}>
                <Box>
                    <Input {...register("name")} placeholder={"Your Name"} />
                    <FormError error={errors.name} />
                </Box>

                <Box>
                    <Input {...register("email")} placeholder={"Your email address"} type={"email"} />
                    <FormError error={errors.email} />
                </Box>

                <Box>
                    <Input {...register("practiceName")} placeholder={"Practice Name"} />
                    <FormError error={errors.practiceName} />
                </Box>

                <Box>
                    <Input {...register("practiceZipCode")} placeholder={"Practice Zip Code"} />
                    <FormError error={errors.practiceZipCode} />
                </Box>

                <Box>
                    <Controller
                        render={({ field }) => (
                            <Select {...field} placeholder={"Practice Management Software"} options={pmsOptions} />
                        )}
                        name={"practiceManagementSoftwareType"}
                        control={control}
                    />
                    <FormError error={errors.practiceManagementSoftwareType} />
                </Box>

                <Box>
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <Select {...field} placeholder={"Type of Practice"} options={practiceTypeOptions} />
                        )}
                        name={"practiceType"}
                    />
                    <FormError error={errors.practiceType} />
                </Box>
                <Box />
                <FlexRowAlignCenter>
                    <BigCheckbox
                        fill={"sienna"}
                        border={"none"}
                        backgroundColor={"white"}
                        isChecked={isCanada}
                        onClick={() => setIsCanada((prevState) => !prevState)}
                    />
                    <Box pl={"12px"} />
                    <Body16>My practice is in Canada</Body16>
                </FlexRowAlignCenter>
            </Grid>
            <YSpacer spaceY={buttonSpace} />
            <FlexColAlignCenter>
                <OrangeButton text={"Get Qualified"} />
            </FlexColAlignCenter>
        </form>
    );
};
