import { createSlice } from "@reduxjs/toolkit";
import { AssessmentFormFields } from "../../types/assessmentFormFields";

const initialState: AssessmentFormFields = {
    name: "",
    email: "",
    practiceManagementSoftwareType: null,
    practiceName: "",
    practiceType: null,
    practiceZipCode: "",
};

export default createSlice({
    name: "assessmentForm",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
