import { Box } from "../styledSystemUtilities";
import React from "react";
import { Body14 } from "../typography";

export function FormError({ error }: { error: any }): JSX.Element | null {
    return error ? (
        <Box mt={5} ml={5}>
            <Body14 color={"shockingOrange"}>{error.message}</Body14>
        </Box>
    ) : null;
}
