import styled from "styled-components";
import { all } from "../styledSystemUtilities";

export const Input = styled.input`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 50px;
    color: #666666;
    font-size: 16px;
    padding-left: 20px;
    ::placeholder {
        color: #bcbcbc;
    }
    :focus {
        outline: none;
    }
    ${all}
`;
