import { parseISO } from "date-fns/fp";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function getMonthAbbreviation(index: number): string {
    return monthNames[index];
}

function useDateParser(key: string, value: string): unknown {
    if (key === "date" || key === "createdAt" || key === "purchaseDate") {
        const date = parseISO(value);
        return getUserTimezoneAdjustedDate(date);
    }
    return value;
}

function getUserTimezoneAdjustedDate(date: Date): Date {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}

function ordinalSuffixOf(i: number): string {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const utils = {
    useDateParser,
    getUserTimezoneAdjustedDate,
    getMonthAbbreviation,
    ordinalSuffixOf,
};

export default utils;
