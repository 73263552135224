import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { configureStore, Reducer } from "@reduxjs/toolkit";
import middleware from "./middleware";
import { AnyAction } from "redux";
import { hydrateState } from "./utilityActions";

const store = configureStore({
    reducer: makeHydratable(rootReducer),
    middleware: [middleware.multi, thunk, middleware.noop, middleware.history, middleware.api],
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

function makeHydratable(reducer: Reducer<ReturnType<typeof rootReducer>>): Reducer<ReturnType<typeof rootReducer>> {
    return function (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) {
        if (action.type === hydrateState.type) {
            return reducer(action.payload, action);
        }
        return reducer(state, action);
    };
}
