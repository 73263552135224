import api from "./api";
import history from "./history";
import multi from "./multi";
import noop from "./noop";

const middleware = {
    api,
    history,
    multi,
    noop,
};

export default middleware;
