import React from "react";

import { Color } from "../types/props/color";
import { ReactComponent as RawAscentLogo } from "./ascentLogo.svg";
import styled from "styled-components";
import { all, Box } from "../Components/styledSystemUtilities";
import { AllStyledSystemProps } from "../types/props/allStyledSytemProps";
import { ReactComponent as RawDentistCaricature } from "./dentistCaricature.svg";
import { ReactComponent as RawPatientCaricature } from "./patientCaricature.svg";
import { ReactComponent as RawMoneySack } from "./moneySack.svg";
import { ReactComponent as RawThumbsUpWoman } from "./thumbsUpWoman.svg";
import { ReactComponent as RawBigCheck } from "./bigCheck.svg";
import { ReactComponent as RawPin } from "./pin.svg";
import { ReactComponent as RawUpsideDownPin } from "./upsideDownPin.svg";
import { ReactComponent as RawReverseGradient } from "./reverseGradient.svg";
import { ReactComponent as BestForDentistryLogo } from "./bestForDentistryLogo.svg";
import { ReactComponent as RawAscentLogoAligned } from "./alignedLogo.svg";

import vanessa from "./vanessa.png";
import hillary from "./hillary.png";
import { useAppSelector } from "../redux/hooks";

export { ReactComponent as WebTools } from "./webTools.svg";
export { ReactComponent as DropdownIndicatorClosed } from "./dropdownIndicatorClosed.svg";
export { ReactComponent as DropdownIndicatorOpen } from "./dropdownIndicatorOpen.svg";
export { ReactComponent as DrChaoHeadshot } from "./drChaoHeadshot.svg";
export { ReactComponent as BlueCalendarIcon } from "./blueCalendarIcon.svg";
export { ReactComponent as WhiteCalendarIcon } from "./whiteCalendarIcon.svg";
export { ReactComponent as XMark } from "./xMark.svg";
export { ReactComponent as CheckMark } from "./checkMark.svg";
export { ReactComponent as StepOne } from "./stepOne.svg";
export { ReactComponent as StepTwo } from "./stepTwo.svg";
export { ReactComponent as YoutubeLogo } from "./youtubeIcon.svg";
export { ReactComponent as FacebookLogo } from "./facebookIcon.svg";
export { ReactComponent as InstagramLogo } from "./instagramIcon.svg";
export { ReactComponent as DropdownArrow } from "./dropdownArrow.svg";

export { ReactComponent as LocalPin } from "./localPin.svg";
export { ReactComponent as ReviewsStars } from "./reviewsStars.svg";
export { ReactComponent as GoogleAdsIcon } from "./googleAdsIcon.svg";

export { ReactComponent as AdecLogo } from "./adecLogo.svg";

export const DentistCaricature = styled(RawDentistCaricature)<AllStyledSystemProps>`
    ${all}
`;

export const PatientCaricature = styled(RawPatientCaricature)<AllStyledSystemProps>`
    ${all}
`;

export const ThumbsUpWoman = styled(RawThumbsUpWoman)<AllStyledSystemProps>`
    ${all}
`;

export const MoneySack = styled(RawMoneySack)<AllStyledSystemProps>`
    ${all}
` as (props: AllStyledSystemProps) => JSX.Element;

export const AscentLogoWithProps = styled(RawAscentLogo)<{ fill?: Color } & AllStyledSystemProps>`
    fill: ${(props) => (props.fill ? props.theme.colors[props.fill] : props.theme.colors.silverChalice)};
    ${all}
`;

export const AscentLogo = ({ width }: { width?: string }) => {
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);

    return (
        <Box cursor={"pointer"} onClick={() => window.open("https://ascentpgs.com", "_self")}>
            {isBestForDentistry ? <BestForDentistryLogo width={width} /> : <AscentLogoWithProps width={width} />}
        </Box>
    );
};

export const AscentLogoAligned = ({ width }: { width?: string }) => {
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);

    return (
        <Box cursor={"pointer"} onClick={() => window.open("https://ascentpgs.com", "_self")}>
            {isBestForDentistry ? <BestForDentistryLogo width={width} /> : <RawAscentLogoAligned width={width} />}
        </Box>
    );
};

export const BigCheck = styled(RawBigCheck)<{ fill: Color } & AllStyledSystemProps>`
    fill: ${(props) => props.theme.colors[props.fill]};
    ${all}
`;

export const Vanessa = () => {
    return <img src={vanessa} alt={"Vanessa"} width={"88px"} />;
};

export const Hillary = () => {
    return <img src={hillary} alt={"Vanessa"} width={"88px"} />;
};

export const Pin = styled(RawPin)`
    ${all}
`;

export const UpsideDownPin = styled(RawUpsideDownPin)`
    ${all}
`;

export const ReverseGradient = styled(RawReverseGradient)`
    height: 10px;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    width: 100%;
`;
