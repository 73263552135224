import styled from "styled-components";
import {
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    compose,
    system,
} from "styled-system";
import React from "react";
import { AllStyledSystemProps } from "../types/props/allStyledSytemProps";

const BorderBoxDiv = styled.div`
    box-sizing: border-box;
`;

export const all = compose(
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    system({
        cursor: true,
        writingMode: true,
        textOrientation: true,
        whiteSpace: true,
        transition: true,
        transform: true,
        boxShadow: true,
        backgroundAttachment: true,
        boxSizing: true,
        uppercase: {
            property: "textTransform",
            transform: () => "uppercase",
        },
    })
);

export const Span = styled("span")<AllStyledSystemProps>`
    ${all}
`;

export const Box = styled(BorderBoxDiv)<AllStyledSystemProps>(all);

export const YSpacer = ({ spaceY, ...props }: { spaceY: number | string }): JSX.Element => (
    <Box pb={spaceY} {...props} />
);
export const XSpacer = ({ spaceX, ...props }: { spaceX: number | string }): JSX.Element => (
    <Box pl={spaceX} {...props} />
);

const Flex = ({ ...props }): JSX.Element => <Box display={"flex"} {...props} />;

export const FlexRow = ({ ...props }): JSX.Element => <Flex flexDirection={"row"} {...props} />;

export const Grid = ({ ...props }): JSX.Element => <Box display={"grid"} {...props} />;
export const FlexCol = ({ ...props }): JSX.Element => <Flex flexDirection={"column"} {...props} />;

export const FlexRowAlignCenter = ({ ...props }): JSX.Element => <FlexRow alignItems={"center"} {...props} />;
export const FlexColAlignCenter = ({ ...props }): JSX.Element => <FlexCol alignItems={"center"} {...props} />;
