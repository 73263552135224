import { combineReducers } from "redux";
import slices from "./slices";

const rootReducer = combineReducers({
    assessmentForm: slices.assessmentForm.reducer,
    loading: slices.loading.reducer,
    serverErrors: slices.serverErrors.reducer,
    qualificationReport: slices.qualificationReport.reducer,
    places: slices.places.reducer,
    user: slices.user.reducer,
    strategist: slices.strategist.reducer,
    isPpc: slices.isPpc.reducer,
    isBestForDentistry: slices.isBestForDentistry.reducer,
});

export default rootReducer;
