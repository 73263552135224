import { Box, YSpacer } from "../../styledSystemUtilities";
import { BlueCalendarIcon } from "../../../assets";
import { Header18Bold, Header24Bold, Header36SemiBold } from "../../typography";
import { OrangeButton } from "../../buttons/OrangeButton";
import React from "react";

export const ScheduleButton = () => (
    <OrangeButton
        backgroundColor={"greyBlue"}
        onClick={() => window.open("https://calendly.com/mike-s-5/ascent-marketing-consultation", "_blank")}
        text={"schedule a demo"}
    />
);

export const ScheduleFreeDemoDesktop = () => (
    <>
        <BlueCalendarIcon />
        <YSpacer spaceY={"30px"} />
        <Box maxWidth={"870px"}>
            <Header36SemiBold lineHeight={"44px"} textAlign={"center"}>
                Questions? Schedule a FREE 30 minute demo to speak with one of our Marketing Strategists.
            </Header36SemiBold>
        </Box>

        <YSpacer spaceY={"27px"} />
        <ScheduleButton />
    </>
);

export const ScheduleFreeDemoTablet = () => (
    <>
        <BlueCalendarIcon />
        <YSpacer spaceY={"30px"} />
        <Header24Bold lineHeight={"32px"} textAlign={"center"}>
            Questions? Schedule a FREE 30 minute demo to speak with one of our Marketing Strategists.
        </Header24Bold>
        <YSpacer spaceY={"27px"} />
        <ScheduleButton />
    </>
);

export const ScheduleFreeDemoMobile = () => (
    <>
        <BlueCalendarIcon />
        <YSpacer spaceY={"20px"} />
        <Box>
            <Header18Bold lineHeight={"22px"} textAlign={"center"}>
                Questions? Schedule a FREE 30 minute demo to speak with one of our Marketing Strategists.
            </Header18Bold>
        </Box>

        <YSpacer spaceY={"27px"} />
        <ScheduleButton />
    </>
);
