import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash/fp";

const initialState = {
    places: false,
    qualificationReport: false,
    createAccount: false,
};

type LoadingLabels = keyof typeof initialState;

export default createSlice({
    name: "loading",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<LoadingLabels>) => _.set(action.payload, true, state),
        unset: (state, action: PayloadAction<LoadingLabels>) => _.set(action.payload, false, state),
    },
});
