import theme from "../theme";
import { DESKTOP, MOBILE, TABLET } from "../constants/deviceTypes";
import { DeviceType } from "../types/deviceType";
import { useWindowWidth } from "./useWindowWidth";

export const useDeviceType = (): DeviceType => {
    const width = useWindowWidth();

    if (width > theme.breakpoints.desktop) {
        return DESKTOP;
    }

    if (width > theme.breakpoints.tablet) {
        return TABLET;
    }

    return MOBILE;
};
