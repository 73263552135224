import { createSlice } from "@reduxjs/toolkit";
import { Place } from "../../types/place";

const initialState: Place[] = [
    // {
    //     placeId: "ChIJiYASwxFZUYgRRi2ARAErgJc",
    //     rating: 4.7,
    //     practiceName: "Carolina Crossroads Dental Care",
    //     address: "1316 Davie Ave Suite B, Statesville, NC 28677, United States",
    //     numReviews: 32,
    // },
];

export default createSlice({
    name: "places",
    initialState: initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
