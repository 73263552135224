import { AscentLogo, AscentLogoAligned, FacebookLogo, InstagramLogo, YoutubeLogo } from "../../../assets";
import {
    FlexCol,
    FlexColAlignCenter,
    FlexRow,
    FlexRowAlignCenter,
    XSpacer,
    YSpacer,
} from "../../styledSystemUtilities";
import { Body14, Body16, Header24 } from "../../typography";
import React from "react";

const phoneNumber = "801-285-5672";

const FacebookLink = () => (
    <a href={"https://www.facebook.com/AscentPracticeGrowthStrategies/"} target="_blank">
        <FacebookLogo />
    </a>
);

const InstagramLink = () => (
    <a href={"https://www.instagram.com/ascent.pgs/"} target="_blank">
        <InstagramLogo />
    </a>
);

const YoutubeLink = () => (
    <a href={"https://www.youtube.com/channel/UCoo_XeGmZwRFWkiSzOap55g/featured"} target="_blank">
        <YoutubeLogo />
    </a>
);

export const BottomRowDesktop = () => (
    <FlexRow width={"100%"} justifyContent={"space-between"}>
        <AscentLogo width={"205px"} />
        <FlexColAlignCenter>
            <FlexRowAlignCenter>
                <FacebookLink />
                <XSpacer spaceX={"12px"} />
                <InstagramLink />
                <XSpacer spaceX={"12px"} />
                <YoutubeLink />
            </FlexRowAlignCenter>
            <YSpacer spaceY={"10px"} />
            <Body16 color={"smokeyGrey"}>© 2022 Ascent Predictive Growth Solutions</Body16>
        </FlexColAlignCenter>
        <FlexCol alignItems={"right"} justifyContent={"center"}>
            <Body16 color={"smokeyGrey"} textAlign={"right"}>
                Schedule a FREE demo
            </Body16>
            <Header24 textAlign={"right"}>{phoneNumber}</Header24>
        </FlexCol>
    </FlexRow>
);

export const BottomRowMobile = () => (
    <FlexColAlignCenter width={"100%"}>
        <AscentLogoAligned width={"225px"} />
        <YSpacer spaceY={"50px"} />
        <FlexRowAlignCenter>
            <FacebookLink />
            <XSpacer spaceX={"12px"} />
            <InstagramLink />
            <XSpacer spaceX={"12px"} />
            <YoutubeLink />
        </FlexRowAlignCenter>
        <YSpacer spaceY={"30px"} />
        <Body16 color={"smokeyGrey"} textAlign={"right"}>
            Schedule a FREE demo
        </Body16>
        <Header24 textAlign={"right"}>{phoneNumber}</Header24>
        <YSpacer spaceY={"24px"} />
        <Body14 color={"smokeyGrey"}>© 2022 Ascent Predictive Growth Solutions</Body14>
    </FlexColAlignCenter>
);
