import React, { useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import theme from "./theme";
import Qualification from "./Components/Pages/Qualification";
import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import _ from "lodash/fp";
import { useAppDispatch } from "./redux/hooks";
import { slices } from "./redux";

const GlobalStyle = createGlobalStyle`
      body{
          font-family: "Roboto", sans-serif;
          overflow-x: hidden;
          height: 100%;
      }
      
      html {
          box-sizing: border-box;
          -webkit-text-size-adjust:100%;
          height:100%;
      }
      
      *, *:before, *:after {
          box-sizing: inherit;
      }
`;

const assessmentFormCopy = {
    header: "Step 1",
    subHeader: "See if you qualify and how you compare to\n" + "other dentists in your zip code within seconds.",
    paragraph1:
        "When you see if you qualify for our revolutionary service, a lot more is going on than meets the eye. Once you submit your information below, we identify a unique code that is linked to your Google My Business account. We then use that code to access dozens of key data points that help us understand exactly the competitive landscape in your area and what Ascent can do to help you flourish. ",
    paragraph2:
        "Once qualified, you’ll start the process by setting up your account and scheduling a kick-off call with your Ascent Success Manager.",
    paragraph3: "It’s a free assessment! Filling out this information does not obligate you to anything. ",
};

const ppcAssessmentFormCopy = {
    header: "Run your free assessment",
    subHeader:
        "In seconds, see how you compare to other dentists in your zip code and if you qualify for zero-risk per-patient pricing.",
    paragraph1:
        "Once you submit your information below, we identify a unique code that is linked to your Google My Business account. We then use that code to access five key digital marketing metrics specific to your practice:",
    paragraph2:
        "• Your area’s overall competitive level\n" +
        "• Your overall online visibility ranking\n" +
        "• Your ranking for the top 5 local keywords\n" +
        "• Your Google reviews ranking\n" +
        "• Your area’s price per click ",
    paragraph3: "It’s a free assessment! Filling out this information does not obligate you to anything. ",
    paragraph4:
        "Additionally, you’ll see if you qualify for zero-risk per-patent billing. This will mean that you son’t have to pay a dime for marketing services until after we can prove, by name, that we’ve helped bring a patient in your door. ",
};

export type AssessmentFormCopy = {
    header: string;
    subHeader: string;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4?: string;
};

function App() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (_.includes("qualifybest", `${window.location.origin}`)) {
            dispatch(slices.isBestForDentistry.actions.set(true));
        }
    }, []);

    return (
        <>
            <Normalize />
            <GlobalStyle />
            <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY ?? ""}>
                <Elements>
                    <ThemeProvider theme={theme}>
                        <Routes>
                            <Route path={"/"} element={<Qualification />} />
                            <Route path={"/:discountIdentifier"} element={<Qualification />} />
                            <Route path={"*"} element={<Navigate to={"/"} />}></Route>
                        </Routes>
                    </ThemeProvider>
                </Elements>
            </RecurlyProvider>
        </>
    );
}

export default App;
