import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../types/utilityActions";
import { UserInput, UserPlace } from "../../types/userInput";

const getQualificationReport = (input: UserInput, place: UserPlace): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/getReport`,
            method: "post",
            data: {
                place,
                input,
            },
        },
        success: slices.qualificationReport.actions.set,
        label: "qualificationReport",
    });

export default getQualificationReport;
