import { useDeviceType } from "./useDeviceType";
import { DESKTOP, TABLET } from "../constants/deviceTypes";
import { DeviceType } from "../types/deviceType";

export const useContentSizes = (): { deviceType: DeviceType; gridTemplateColumns: string } => {
    const deviceType = useDeviceType();
    const contentWidth =
        deviceType === DESKTOP ? "1125px" : deviceType === TABLET ? "minmax(600px, 850px)" : "minmax(280px, 400px)";
    const gridTemplateColumns = `1fr 20px ${contentWidth} 20px 1fr`;

    return {
        deviceType,
        gridTemplateColumns,
    };
};
