import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StrategistInfo } from "../../types/strategist";

const initialState: StrategistInfo | null = null;

// {
//     name: "Vanessa",
//     email: "vanessa.t@ascentpgs.com",
//     _id: "5eb45bd6ecde750004bb547a",
// };

export default createSlice<StrategistInfo | null, any>({
    name: "strategist",
    initialState: initialState,
    reducers: {
        set: (state: StrategistInfo | null, action: PayloadAction<StrategistInfo>) => action.payload,
    },
});
