import styled from "styled-components";
import theme from "../../theme";
import { all } from "../styledSystemUtilities";

const Button = styled.button`
    background-color: ${theme.colors.shockingOrange};
    border-radius: 30px;
    border: none;
    font-family: roboto;
    font-size: 16px;
    width: 246px;
    height: 53px;
    color: ${theme.colors.white};
    :hover {
        cursor: pointer;
    }
    ${all}
`;

export const OrangeButton = ({ text, ...props }: { text: string } & any) => {
    return <Button {...props}>{text.toUpperCase()}</Button>;
};
