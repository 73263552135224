import assessmentForm from "./assessmentForm";
import loading from "./loading";
import serverErrors from "./serverErrors";
import qualificationReport from "./qualificationReport";
import places from "./places";
import user from "./user";
import strategist from "./strategist";
import isPpc from "./isPpc";
import isBestForDentistry from "./isBestForDentistry";

const slices = {
    assessmentForm,
    loading,
    serverErrors,
    qualificationReport,
    places,
    user,
    strategist,
    isPpc,
    isBestForDentistry,
};

export default slices;
