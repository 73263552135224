import { useContentSizes } from "./useContentSizes";
import { useAppSelector } from "../redux/hooks";
import { useIsQualified } from "./useIsQualified";

export const useQualificationProps = () => {
    const { gridTemplateColumns, deviceType } = useContentSizes();

    const isLoading = useAppSelector((state) => state.loading.qualificationReport);
    const report = useAppSelector((state) => state.qualificationReport);
    const { qualifiedFor, practiceType, isValidPracticeType, isQualified } = useIsQualified();

    return {
        gridTemplateColumns,
        deviceType,
        isValidPracticeType,
        isLoading,
        qualifiedFor,
        practiceType,
        report,
        isQualified,
    };
};
