import React from "react";
import styled, { css } from "styled-components";
import { all } from "../styledSystemUtilities";
import { OpacityProps, TextAlignProps } from "styled-system";
import { AllStyledSystemProps } from "../../types/props/allStyledSytemProps";
import { Color } from "../../types/props/color";
import { FontWeight } from "../../types/props/fontWeight";
import theme from "../../theme";

const base = css`
    font-weight: 400;
    color: #707070;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin: 0;
`;

export const Body = styled("p")<AllStyledSystemProps>`
    ${base};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 20px;
    ${all}
`;

export const Helvetica = styled("p")<AllStyledSystemProps>`
    ${base};
    font-family: "Helvetica Neue";
    font-size: 20px;
    line-height: 24px;
    ${all}
`;

export const Header = styled("h2")<AllStyledSystemProps>`
    ${base};
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: ${theme.colors.darkGunmetal};
    ${all}
`;
export const Label = styled("p")<AllStyledSystemProps>`
    ${base};
    font-family: Roboto, sans-serif;
    font-size: 10px;
    line-height: 14px;
    ${all}
`;

export type FontProps = TextAlignProps &
    OpacityProps & {
        color?: Color;
        fontWeight?: FontWeight | number;
        fontSize?: string;
        lineHeight?: string;
        uppercase?: boolean;
        fontFamily?: "montserrat" | "roboto";
        whiteSpace?: string;
        children?: React.ReactNode;
    };

export const TextFrom = (Text: (props: FontProps) => JSX.Element) => (a: FontProps) => (b: FontProps): JSX.Element => (
    <Text {...a} {...b} />
);

export const HeaderWith = TextFrom(Header);
export const Header64 = HeaderWith({ fontSize: "64px", lineHeight: "80px" });
export const Header64Bold = TextFrom(Header64)({ fontWeight: "bold" });
export const Header48 = HeaderWith({ fontSize: "48px", lineHeight: "52px" });
export const Header48Bold = TextFrom(Header48)({ fontWeight: "bold" });
export const Header44 = HeaderWith({ fontSize: "44px", lineHeight: "56px" });
export const Header44Bold = TextFrom(Header44)({ fontWeight: "bold" });
export const Header36 = HeaderWith({ fontSize: "36px", lineHeight: "44px" });
export const Header36Bold = TextFrom(Header36)({ fontWeight: "bold" });
export const Header36SemiBold = TextFrom(Header36)({ fontWeight: "semiBold" });
export const Header32 = HeaderWith({ fontSize: "32px", lineHeight: "40px" });
export const Header32Bold = TextFrom(Header32)({ fontWeight: "bold" });
export const Header28 = HeaderWith({ fontSize: "28px", lineHeight: "34px" });
export const Header28Bold = TextFrom(Header28)({ fontWeight: "bold" });
export const Header24 = HeaderWith({ fontSize: "24px", lineHeight: "40px" });
export const Header24Bold = TextFrom(Header24)({ fontWeight: "bold" });
export const Header24SemiBold = TextFrom(Header24)({ fontWeight: "semiBold" });
export const Header18 = HeaderWith({ fontSize: "18px", lineHeight: "22px" });
export const Header18Bold = TextFrom(Header18)({ fontWeight: "bold" });
export const Header18SemiBold = TextFrom(Header18)({ fontWeight: "semiBold" });
export const Header16 = HeaderWith({ fontSize: "16px", lineHeight: "16px" });

const BodyWith = TextFrom(Body);
export const Body24 = BodyWith({ fontSize: "24px", lineHeight: "24px" });
export const Body18 = BodyWith({ fontSize: "18px", lineHeight: "18px" });
export const Body18Bold = TextFrom(Body18)({ fontWeight: "bold" });
export const Body18Light = TextFrom(Body18)({ fontWeight: 100 });
export const Body14 = BodyWith({ fontSize: "14px", lineHeight: "14px" });
export const Body14Light = TextFrom(Body14)({ fontWeight: 100 });
export const Body36 = BodyWith({ fontSize: "36px", lineHeight: "52px" });
export const Body36Medium = TextFrom(Body36)({ fontWeight: "bold" });
export const Body24Light = TextFrom(Body24)({ fontWeight: 100 });
export const Body24medium = TextFrom(Body24)({ fontWeight: "medium" });

export const Header200 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"200px"} lineHeight={1.2} fontWeight={"semiBold"} opacity={0.3} {...props} />
);
export const Header100 = (props: FontProps): JSX.Element => (
    <Body as={"h1"} fontSize={"100px"} lineHeight={1.2} fontWeight={"thin"} {...props} />
);

export const Header58Bold = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"58px"} lineHeight={"62px"} fontWeight={"bold"} {...props} />
);
export const Header58 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"58px"} lineHeight={"62px"} {...props} />
);

export const Header33 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"33px"} lineHeight={"52px"} {...props} />
);

export const Header23 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"23px"} lineHeight={"27px"} {...props} />
);

export const Body26 = (props: FontProps): JSX.Element => <Body fontSize={"26px"} lineHeight={"26px"} {...props} />;

export const Body17 = (props: FontProps): JSX.Element => <Body fontSize={"17px"} {...props} />;
export const Body16 = (props: FontProps): JSX.Element => <Body fontSize={"16px"} {...props} />;

export const Body14Medium = (props: FontProps): JSX.Element => (
    <Body14 fontWeight={"medium"} {...props} children={props.children} />
);
export const Body14Bold = (props: FontProps): JSX.Element => (
    <Body14 fontWeight={"bold"} {...props} children={props.children} />
);

export const Body12 = (props: FontProps): JSX.Element => <Body fontSize={"12px"} lineHeight={"16px"} {...props} />;
export const Body12Medium = (props: FontProps): JSX.Element => (
    <Body12 fontWeight={"medium"} {...props} children={props.children} />
);
export const Body12Bold = (props: FontProps): JSX.Element => (
    <Body12 fontWeight={"bold"} {...props} children={props.children} />
);
export const BodyMedium = (props: FontProps): JSX.Element => <Body fontWeight={"medium"} {...props} />;
export const BodyBold = (props: FontProps): JSX.Element => <Body fontWeight={"bold"} {...props} />;
export const Body20Bold = (props: FontProps): JSX.Element => <Body fontWeight={"bold"} fontSize={"20px"} {...props} />;

export const Header16Bold = (props: FontProps): JSX.Element => (
    <Header16 fontWeight={"bold"} {...props} children={props.children} />
);

export const Label9 = (props: FontProps): JSX.Element => <Label fontSize={"9px"} {...props} />;
export const Label9Medium = (props: FontProps): JSX.Element => (
    <Label fontSize={"9px"} fontWeight={"medium"} {...props} />
);
export const LabelBold = (props: FontProps): JSX.Element => <Label fontWeight={"bold"} {...props} />;
export const LabelBlack = (props: FontProps): JSX.Element => <Label fontWeight={"black"} {...props} />;
export const LabelMedium = (props: FontProps): JSX.Element => <Label fontWeight={"medium"} {...props} />;
export const Label11 = (props: FontProps): JSX.Element => <Label fontSize={"11px"} {...props} />;
export const Label11Bold = (props: FontProps): JSX.Element => (
    <Label11 fontWeight={"bold"} {...props} children={props.children} />
);
export const Label11Medium = (props: FontProps): JSX.Element => (
    <Label11 fontWeight={"medium"} {...props} children={props.children} />
);
