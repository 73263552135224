import { historyPush } from "../utilityActions";
import { routerHistory } from "../../routerHistory";
import { Middleware } from "redux";

const history: Middleware = () => (next) => async (action) => {
    if (action.type !== historyPush.type) {
        return next(action);
    }
    routerHistory.push(action.payload);
};

export default history;
