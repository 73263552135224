import React from "react";
import { Box, FlexCol, FlexRow, Grid, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Body14, Body16, Header18Bold, Header24Bold, Header32Bold } from "../typography";
import { AscentLogo } from "../../assets";
import { StrategistInfo } from "../../types/strategist";

export const Welcome = ({ strategist }: { strategist: StrategistInfo }) => {
    return (
        <Grid gridTemplateColumns={"1fr 20px minmax(280px, 320px) 20px 1fr"}>
            <FlexCol gridColumn={3}>
                <YSpacer spaceY={"33px"} />
                <AscentLogo />
                <YSpacer spaceY={"33px"} />
                <Header32Bold textAlign={"center"}>Welcome!</Header32Bold>
                <YSpacer spaceY={"12px"} />
                <FlexRow alignSelf={"center"}>
                    <XSpacer spaceX={"10px"} />
                    <Box>
                        <Body16>Your Success Manager will be {strategist.name}</Body16>
                        <YSpacer spaceY={"4px"} />
                        <Body14 color={"shadowGreen"}>{strategist.email}</Body14>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={"32px"} />
                <Header24Bold>What's next?</Header24Bold>
                <YSpacer spaceY={"8px"} />
                <Body16 lineHeight={"24px"}>
                    It’s time to schedule your kick-off meeting with your Ascent Success Manager. During your kick-off
                    meeting you will:
                    <br />
                    <br />• Assess your growth goals
                    <br />• Set expectations
                    <br />• Verify you have downloaded and can access the mobile Ascent Reporting App
                    <br />• Ensure all your tacking and reporting integration is completed.
                </Body16>
                <YSpacer spaceY={"48px"} />
                <Header18Bold>Schedule your kick-off meeting with {strategist.name} below.</Header18Bold>
                <YSpacer spaceY={"20px"} />
                <iframe
                    src={strategist.calendlyLink}
                    title="Schedule Appointment"
                    width="100%"
                    height={"1400px"}
                    frameBorder="0"
                />
            </FlexCol>
        </Grid>
    );
};
