import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

export default createSlice({
    name: "isBestForDentistry",
    initialState: initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
