import { useAppSelector } from "../redux/hooks";

type Qualification = "velox" | "callRail" | "none";

export const useIsQualified = () => {
    const practiceManagementSoftware = useAppSelector((state) => state.user.practiceManagementSoftware);
    const practiceType = useAppSelector((state) => state.user.practiceType);

    const hasValidPms =
        practiceManagementSoftware === "dentrix" ||
        practiceManagementSoftware === "eaglesoft" ||
        practiceManagementSoftware === "openDental";

    const invalidPracticeTypes = ["periodontist", "endodontist", "prosthodontist", "oralSurgeon"];

    const isValidPracticeType = !invalidPracticeTypes.includes(practiceType);

    const isQualified = isValidPracticeType;

    const qualifiedFor: Qualification =
        isValidPracticeType && hasValidPms ? "velox" : isValidPracticeType ? "callRail" : "none";

    return {
        qualifiedFor,
        isValidPracticeType,
        practiceType,
        isQualified,
    };
};
