import { Box, FlexRowAlignCenter } from "../../styledSystemUtilities";
import { Body } from "../../typography";
import React from "react";

export const WarningCircle = () => (
    <Box width={"56px"}>
        <FlexRowAlignCenter
            justifyContent={"center"}
            backgroundColor={"shockingOrange"}
            height={"56px"}
            width={"56px"}
            borderRadius={"50%"}
        >
            <Body fontSize={"40px"} fontWeight={"black"} color={"white"}>
                !
            </Body>
        </FlexRowAlignCenter>
    </Box>
);
